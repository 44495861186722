@charset "UTF-8";
@use "settings" as settings;

//header

body{
	background-color: #000;
	background-image: url(../img/bg.gif);
}

.header{
	display: flex;
	justify-content: center;
	height: 85px;
	padding-top: 9px;
	&__logo{
		width: 237px;
	}
}

//footer

.footer{
	padding-bottom: 20px;
	.footertag{
		margin-top: 40px;
		.tickercontainer{
			height: auto !important;
		}
		&__list{
			// display: flex;
			// flex-wrap: wrap;
		}
		&__item{
			padding: 0 !important;
		}
		&__link{
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.6rem;
			height: 52px;
			padding: 0 10px;
			border: 1px solid #000;
			white-space: nowrap;
		}
	}
	.pagenav{
		position: relative;
		margin-top: 34px;
		&__item{
			&--gohome{
				width: 114px;
				margin: auto;
			}
			&--gotop{
				width: 39px;
				position: absolute;
				top: 15px;
				right: 15px;
			}
		}
	}
	
	.copyright{
		display: block;
		text-align: center;
		font-size: .8rem;
		color: #fff;
		margin-top: 24px;
	}
}
.wrapper{
	margin: auto;
	@include settings.mq(w767){
		width: 700px;
		padding: 0 15px;
	}
	@include settings.mq(w1080){
		width: 1050px;
	}
	@include settings.mq(w1440){
		width: 1440px;
		//padding: 0 20px;
	}
}
body{
	&.tag{
		width: 100%;
		margin: 0;
	}
	&.search{
		margin: 0;
	}
}
.tag{
	//width: 100%;
	width: 330px;
	margin: 20px auto 0 auto;
	@include settings.mq(w767){
		width: 700px;
		padding: 0 15px;
	}
	@include settings.mq(w1080){
		width: 1050px;
	}
	@include settings.mq(w1440){
		width: 1440px;
		//padding: 0 20px;
	}
	&__list{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&::after{
			content: '';
			display: block;
			width: 100px;
		}
	}
	&__item{
		flex-grow: 100;
		//min-width: 20%;
		&:first-child{
			flex-grow: 0;
		}
		@include settings.mq(w980){
			min-width: auto;
		}
		// &:last-child{
		// 	flex-grow: 0;
		// }
	}
	&__link{
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1;
		border: 2px solid #000;
		height: 52px;
		background: #fff;
		color: #000;
		padding: 0 10px;
		&--dnp-shuei-mgothic{
			font-family: tbcgothic-std, sans-serif;
			font-weight: 800;
			font-style: normal;
			flex-grow: 2;
		}
		&--kantypos-415{
			font-family: kan415typos-std, sans-serif;
			font-weight: 400;
			font-style: normal;
		}
		&--ro-brush{
			font-family: ro-brush-std, sans-serif;
			font-weight: 400;
			font-style: normal;
		}
		&--toppan-bunkyu-midashi-min{
			font-family: toppan-bunkyu-midashi-min-st, serif;
			font-weight: 900;
			font-style: normal;
		}
		&--tbcgothic{
			font-family: tbcgothic-std, sans-serif;
			font-weight: 800;
			font-style: normal;
		}
		&--vdl-logojrblack{
			font-family: vdl-logojrblack, sans-serif;
			font-weight: 900;
			font-style: normal;
		}
		&--dnp-shuei-gothic-gin{
			font-family: dnp-shuei-gothic-gin-std, sans-serif;
			font-weight: 500;
			font-style: normal;
		}
		&--vdl-v7marugothic{
			font-family: vdl-v7marugothic, sans-serif;
			font-weight: 900;
			font-style: normal;
		}
		&.italic{
			font-style: italic;
		}
		&.bg-white{
			background: #fff;
		}
		&.bg-blue{
			background: #3ebee6;
		}
		&.bg-pink{
			background: #e79dc2;
		}
	}
}

.sns{
	margin-top: 36px;
	&__head{
		width: 75px;
		height: auto;
		margin: 0 auto 21px auto;
	}
	&__list{
		display: flex;
		justify-content: center;
	}
	&__item:not(:first-child){
		margin-left: 21px;
	}
	&__link{
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
		transition: all .2s;
		&:hover{
			opacity: 1;
			color: #fff;
		}
		&--twitter{
			font-size: 2rem;
			.fab{
				transform: translate(2px, 1px);
			}
			&:hover{
				background: #1da1f2;
			}
		}
		&--fb{
			font-size: 2rem;
			.fab{
				transform: translate(1px, 1px);
			}
			&:hover{
				background: #3c5a99;
			}
		}
		
	}
}

.snsbox{
	width: 330px;
	margin: 60px auto 0 auto;
	@include settings.mq(w767){
		width: 100%;
		display: flex;
		justify-content: center;
		.fb,.twitter{
			width: 330px;
			padding: 0 15px;
		}
	}
	.fb+.twitter{
		margin-top: 30px;
		@include settings.mq(w767){
			margin-top: 0;
			//margin-left: 20px;
		}
	}
}

.nopost{
	color: #fff;
	text-align: center;
}
// tag & search
.tagpage,
.search{
	.tag__header{
		padding: 0 20px;
	}
	.tag__title{
		font-family: fot-tsukuardgothic-std, sans-serif;
		font-weight: 700;
		font-style: normal;
		font-size: 1.6rem;
		line-height: 1.4;
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 2px solid #fff;
		border-bottom: 2px solid #fff;
		width: 100%;
		color: #fff;
		text-align: center;
	}
}
