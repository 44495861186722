@charset "UTF-8";
@use "_reset";
@use "settings" as settings;
@use "user_reset";
@use "common";
@use "slider";
@use "slick";
@use "slick-theme";
@use "lightbox";
@forward "preload";


.scroll{
	background: url(../img/scroll.svg) no-repeat;
	display: block;
	width: 30px;
	height: 12px;
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	background-size: 100%;
	opacity: 0;
	animation: scroll 3.5s ease 1.5s normal infinite none running;
}

@keyframes scroll {
	0% {
	  opacity: 0;
	}
  
	50% {
	  opacity: 1;
	}
  
	100% {
	  opacity: 0;
	}
  }

//news

.news{
	height: 28px;
	background: #fff;
	//overflow: hidden;
	width: 100%;
	white-space: nowrap;
	&__list{
		height: 28px;
		font-family: 'Noto Sans JP', sans-serif !important;
		font-weight: 500 !important;
	}
	&__item{
		font-size: 1.3rem !important;
		line-height: 28px !important;
	}
	&__item + &__item{
		margin-left: 1em;
	}
	&__date,
	&__title{
		line-height: 1 !important;
		display: inline-block;
	}
	&__date + &__title{
		border-left: 1px solid #000;
		margin-left: 5px;
		padding-left: 5px;
	}
}

//latest

.latest{
	margin-top: 34px;
	&__item{
		position: relative;
		padding: 0 13px;
		.pr{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 20px;
			background: #000;
			position: absolute;
			top: 0;
			left: 25px;
			img{
				width: 14px;
				height: auto;
			}
		}
		img{
			height: 100%;
			object-fit: cover;
		}
	}
	.slick-track{
		display: flex;
	}
	.slick-slide{
		height: auto;
	}
}



//gnav

.gnav{
	margin-top: 59px;
	&__list{
		@include settings.mq(w980){
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&__item + &__item{
		margin-top: 14px;
		@include settings.mq(w980){
			margin-top: 0;
			margin-left: 14px;
		}
	}
	&__link{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 325px;
		height: 50px;
		margin: auto;
		border: 3px solid #999999;
		border-radius: 15px;
		box-shadow: 0px 0px 6px 2px rgba(246,235,190,0.7),0px 0px 20px 4px rgba(246,235,190,0.9)inset;
		background : -moz-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
		background : -webkit-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
		background : -webkit-gradient(radial,50% 50% ,0 , 50% 50%, 116.26 ,color-stop(0,rgba(255, 255, 255, 1) ),color-stop(0.2935,rgba(255, 254, 252, 1) ),color-stop(0.5008,rgba(253, 252, 243, 1) ),color-stop(0.681,rgba(251, 247, 227, 1) ),color-stop(0.7114,rgba(251, 246, 224, 1) ),color-stop(0.8147,rgba(250, 245, 220, 1) ),color-stop(0.9067,rgba(249, 242, 210, 1) ),color-stop(0.9941,rgba(246, 236, 192, 1) ),color-stop(1,rgba(246, 236, 190, 1) ));
		background : -o-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
		background : -ms-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
		&--about{
			img{
				width: 239px;
				height: auto;
			}
		}
		&--iriguchi{
			img{
				width: 175px;
				height: auto;
			}
		}
		&--favorit{
			img{
				width: 220px;
				height: auto;
			}
		}
	}
}

//search

.search{
	margin-top: 27px;
	@include settings.mq(w399){
		margin-top: 60px;
		//padding: 0 20px;
	}
	&__head{
		width: 365px;
		margin: auto;
	}
	.keyword{
		width: 325px;
		margin: 28px auto 0 auto;
		position: relative;
		&__input{
			border: none;
			border-radius: 5px;
			width: 100%;
			height: 40px;
			padding: 10px;
			&::placeholder{
				font-weight: 900;
				color: #ccc;
			}
		}
		&__btn{
			position: absolute;
			top: 10px;
			right: 5px;
			border: none;
			background: transparent;
			img{
				width: 15px;
				height: auto;
			}
		}
	}
}


//entry

.entry{
	width: 325px;
	margin: 50px auto 0 auto;
	@include settings.mq(w767){
		width: 700px;
		margin: 50px auto 0 auto;
		display: flex;
		flex-wrap: wrap;
	}
	@include settings.mq(w1080){
		width: 1050px;
	}
	@include settings.mq(w1440){
		width: 1440px;
		//padding: 0 20px;
	}
	&__block{
		@include settings.mq(w767){
			padding: 0 15px;
			margin-bottom: 30px;
			display: block !important;
		}
	}
	a{
		color: #000;
	}
	&__img-wrapper{
		width: 312px;
		margin: auto;
		//border: 5px solid #fff;
		border-radius: 5px;
		//height: 5px;
		position: relative;
		&::before{
			content: '';
			border-radius: 5px;
			background-image: linear-gradient(
				to right, #fff, #fff 100px, transparent 3px, transparent 8px),
				linear-gradient(to right, #fff, #fff 100px, transparent 3px, transparent 8px),
				linear-gradient(to bottom, #fff, #fff 100px, transparent 3px, transparent 8px),
				linear-gradient(to bottom, #fff, #fff 120px, transparent 10px, transparent 8px);
			background-size: 120px 5px, 140px 5px, 5px 120px, 5px 140px;
			background-position: left top, left bottom, left top, right top;
			background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			pointer-events: none;
			//z-index: 1;
		}
		&--new{
			border: 5px solid #eee531;
			position: relative;
			&::before{
				display: none;
			}
			.new{
				position: absolute;
				top: 0;
				left: 0;
				width: 60px;
			}
		}
	}
	&__img{
		width: 302px;
		height: 200px;
		object-fit: cover;
	}
	&__body{
		display: flex;
		justify-content: space-between;
		//align-items: center;
		//width: 325px;
		height: 105px;
		border: 3px solid #999999;
		padding: 15px 15px;
		transform: translateY(-20px);
		border-radius: 15px;
		box-shadow: 0px 0px 6px 2px rgba(246,235,190,0.7),0px 0px 20px 4px rgba(246,235,190,0.9)inset;
		background : -moz-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
		background : -webkit-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
		background : -webkit-gradient(radial,50% 50% ,0 , 50% 50%, 116.26 ,color-stop(0,rgba(255, 255, 255, 1) ),color-stop(0.2935,rgba(255, 254, 252, 1) ),color-stop(0.5008,rgba(253, 252, 243, 1) ),color-stop(0.681,rgba(251, 247, 227, 1) ),color-stop(0.7114,rgba(251, 246, 224, 1) ),color-stop(0.8147,rgba(250, 245, 220, 1) ),color-stop(0.9067,rgba(249, 242, 210, 1) ),color-stop(0.9941,rgba(246, 236, 192, 1) ),color-stop(1,rgba(246, 236, 190, 1) ));
		background : -o-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
		background : -ms-radial-gradient(50% 50%, circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 254, 252, 1) 29.35%, rgba(253, 252, 243, 1) 50.08%, rgba(251, 247, 227, 1) 68.1%, rgba(251, 246, 224, 1) 71.14%, rgba(250, 245, 220, 1) 81.47%, rgba(249, 242, 210, 1) 90.67%, rgba(246, 236, 192, 1) 99.41%, rgba(246, 236, 190, 1) 100%);
	}
	&__sign{
		width: 76px;
		height: 65px;
		img{
			width: 76px;
			height: 65px;
			object-fit: cover;
			border-radius: 10px;
		}
	}
	&__txt{
		width: 189px;
		font-family: toppan-bunkyu-midashi-go-std,sans-serif;
		font-weight: 900;
		font-style: normal;
		display: flex;
		flex-direction: column;
	}
	&__title{
		margin-top: 5px;
		line-height: 1.2;
	}
	&__name{
		order: -1;
		font-size: 1.2rem;
		font-feature-settings: "palt";
		letter-spacing: .2px;
	}
	&__date{
		font-size: .8rem;
		margin-top: auto;
		text-align: right;
	}
	.tag{
		width: 320px;
		margin: 0 auto 0 auto;
		transform: translateY(-10px);
		// &__item{
		// 	&:last-child{
		// 		flex-grow: 0;
		// 	}
		// }
		&__link{
			font-size: 1rem;
			height: 20px;
			border-width: 1px;
		}
	}
	&__block + &__block{
		margin-top: 20px;
		@include settings.mq(w767){
			margin-top: 0;
			//margin-left: 30px;
		}
	}
	// .more{
	// 	color: #fff;
	// 	font-family: toppan-bunkyu-midashi-go-std,sans-serif;
	// 	font-weight: 900;
	// 	font-style: normal;
	// 	font-size: 1.3rem;
	// 	text-align: center;
	// 	margin: 20px 0 0 0;
	// 	.fas{
	// 		margin-left: 5px;
	// 	}
	// }
	.btn.loadMoreBtn {
		display:block;
		color: #fff;
		font-family: toppan-bunkyu-midashi-go-std,sans-serif;
		font-style: normal;
		font-size: 2rem;
		text-align: center;
		margin: 20px 0 0 0;
		border: 1px solid #fff;
		padding: 20px;
		&::after{
			content: '\f107';
			font-family: "FontAwesome";
			font-size: 2rem;
			margin-left: 5px;
		}
	}

	.btn.loadMoreBtn:hover {
		text-decoration: none;
	}
}

//campaign

.campaign{
	padding: 80px 20px 29px 20px;
	&nav{
		max-width: 1240px;
		margin: 40px auto 0 auto;
		&__list{
			display: flex;
			justify-content: space-between;
			@include settings.mq(w767){
				flex-wrap: wrap;
			}
		}
		&__item{
			@include settings.mq(w767){
				width: 50%;
			}
			@include settings.mq(w587){
				width: 100%;
			}
		}
	}
}

.about{
	&__wrapper{
		//margin-top: 25px;
		padding: 0 20px;
		font-family: fot-tsukuardgothic-std,sans-serif;
		font-weight: 400;
		font-style: normal;
		color: #fff;
		margin: 25px auto 0 auto;
		@include settings.mq(w767){
			width: 700px;
			padding: 0 15px;
		}
		@include settings.mq(w1080){
			width: 1050px;
		}
		@include settings.mq(w1440){
			width: 1050px;
			//padding: 0 20px;
		}
	}
	&__head{
		width: 258px;
		margin: auto;
	}
	&__txt{
		font-size: 1.4rem;
		line-height: calc(20 / 14);
		margin-top: 18px;
		p + p{
			margin-top: 1em;
		}
		.note{
			font-size: 1.1rem;
		}
	}
	&__favorite{
		margin-top: 63px;
		p{
			font-family: 'Noto Sans JP', sans-serif;
			font-size: 1.4rem;
			line-height: (23 / 14);
			text-align: center;
			margin-top: 23px;
		}
		tr,th,td{
			display: block;
		}
		th{
			font-family: toppan-bunkyu-midashi-go-std,sans-serif;
			font-weight: 900;
			font-style: normal;
			font-size: 1.2rem;
			display: flex;
			align-items: center;
		}
		tr:last-child{
			margin-top: 30px;
		}
		input[type="text"]{
			width: 100%;
			height: 40px;
			border: none;
			border-radius: 8px;
			margin-top: 10px;
			font-size: 16px;
			padding: 5px;
		}
		textarea{
			width: 100%;
			height: 150px;
			border: none;
			border-radius: 8px;
			margin-top: 10px;
			font-size: 16px;
			padding: 5px;
		}
		input[type="submit"]{
			border: 2px solid #fff;
			background: #000;
			color: #fff;
			border-radius: 0;
			font-size: 1.6rem;
			width: 100px;
			height: 40px;
		}
		.validation{
			width: 25px;
			height: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: .9rem;
			margin-left: 5px;
			&.any{
				background: #808080;
			}
			&.required{
				background: #ff0000;
			}
		}
	}
	&__en{
		margin-top: 47px;
	}
}

