@use 'settings' as s;

@mixin flex-width($w) {
  min-width: #{$w}vw;
  max-width: #{$w}vw;
}

.preload-modal {
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  overflow: hidden;
}

#loading {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  transform: translate(-50%, 0);
  opacity: 0;


  div {
    width: 45%;
    @include s.mq(w767){
      width: 20%;
    }

    img {
      width: 100%;
    }

    &.loading-anime1 {
      margin-top: -50px;
    }

    &.loading-anime2 {
      margin-top: 50px;
    }
  }
}

#first-word {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  font-family: 'YuGothic', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 2;
  letter-spacing: 3px;
  opacity: 0;
  @include s.mq(w767){
    font-size: 40px;
    //line-height: 2.5;
    letter-spacing: 10px;
  }
}

#first-logo {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60vw;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  margin: 0 auto;
  transform: translate(-50%, 0);
  @include s.mq(w767){
    width: 430px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    div {
      opacity: 0;
    }
    .text-3 {
      margin: 0 0 0 -3px;
    }
    .text-4 {
      margin: 0 1px 0 -5px;
    }
    .text-6 {
      margin: 0 -3px 0 -6px;
    }
    @include s.mq(w767){
      .text-3 {
        margin: 0 0 0 -3px;
      }
      .text-4 {
        margin: 0 1px 0 -7px;
      }
      .text-6 {
        margin: 0 -3px 0 -6px;
      }
    }
  }

  .f-stage {
    @include s.mq(w767){
      width: 430px;
    }

    div {
      height: 9vw;
      @include s.mq(w767){
        height: 65px;
      }

      img {
        height: 100%;
        @include s.mq(w767){
          width: initial;
        }
      }
    }
  }

  .s-stage {
    margin: 5px 5px 5px -5px;
    @include s.mq(w767){
      width: 430px;
      margin: 5px 10px 5px -10px;
    }
    div {
      height: 9vw;
      @include s.mq(w767){
        height: 65px;
      }

      img {
        height: 100%;
        @include s.mq(w767){
          width: initial;
        }
      }
    }
  }

  .ja {
    width: 80%;
    margin: 3vw 5px 0 -5px;
    @include s.mq(w767){
      width: 290px;
      margin-top: 20px;
      font-size: 30px;
    }

    img {
      height: 100%;
      @include s.mq(w767){
        width: initial;
      }
    }

    div {
      display: flex;
      height: 4vw;
      align-items: center;
      @include s.mq(w767){
        height: 23px;
      }

      &:nth-child(1) {
        margin: 0 -4px 0 0;
      }
      &:nth-child(3) {
        height: 3vw;
        margin: 0 -5px -5px;
      }
      &:nth-child(5) {
        margin: 0 -2px 0 -4px;
      }
      &:nth-child(6) {
        margin: 0 -2px 0 0;
      }
      &:nth-child(7) {
        height: 3.9vw;
        margin: 0 -7px 0 -5px;
      }
      &:nth-child(8) {
        margin: 0 -1px 0 -3px;
      }
      &:nth-child(9) {
        margin: 0 0 0 -5px;
      }
      &:nth-child(10) {
        @include flex-width(2.5);
      }

      @include s.mq(w767){
        &:nth-child(3) {
          height: 18px;
          margin-bottom: -5px;
        }
        &:nth-child(5) {
          height: 23px;
          margin: 0 -2px 0 -4px;
        }
        &:nth-child(7) {
          height: 21px;
          margin: 0 -7px;
        }
        &:nth-child(8) {
          margin: 0 -3px 0 -5px;
        }
        &:nth-child(9) {
          margin: 0 0 0 -5px;
        }
        &:nth-child(10) {
          min-width: 15px;
          max-width: 15px;
          margin: 0 0 0 -5px;
           img {
             width: 100%;
           }
        }
      }
    }
  }
}

#kv {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  background: url('#{s.$path-img}/kv.jpg') no-repeat;
  background-size: 1000vw;
  background-position-x: 50%;
  background-position-y: 80%;
  @include s.mq(w767){
    background-size: 400vh;
  }
}

.blinking {
  animation: blink 3s alternate;
  opacity: 1!important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  2% {
    opacity: .2;
  }
  3% {
    opacity: .85;
  }
  5% {
    opacity: .4;
  }
  6% {
    opacity: 1;
  }
}
