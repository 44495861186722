.kv {
    width: 100%;
    text-align: left;
    position: relative;
    overflow: hidden;
}
 
.kv ul,
.kv ul li {
    float: left;
    display: inline;
    overflow: hidden;
}
 
.kv ul li img {
    width: 100%;
    display: none;
}
 
.wideslider_base {
    top: 0;
    position: absolute;
}
.wideslider_wrap {
    top: 0;
    position: absolute;
    overflow: hidden;
 
}
.slider_prev,
.slider_next {
    top: 0;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    cursor: pointer;
}
// .slider_prev {background: #fff url(../img/prev.jpg) no-repeat right center;}
// .slider_next {background: #fff url(../img/next.jpg) no-repeat left center;}

.slider_prev {background: #000;}
.slider_next {background: #000;}
 
.pagination {
    display: none;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 15px;
    text-align: center;
    position: absolute;
    z-index: 200;
}
 
.pagination a {
    margin: 0 5px;
    width: 15px;
    height: 15px;
    display: inline-block;
    overflow: hidden;
    background: #333;
}
.pagination a.active {
    filter:alpha(opacity=100)!important;
    -moz-opacity: 1!important;
    opacity: 1!important;
}
 
 
/* =======================================
    ClearFixElements
======================================= */
.kv ul:after {
    content: ".";
    height: 0;
    clear: both;
    display: block;
    visibility: hidden;
}
 
.kv ul {
    display: inline-block;
    overflow: hidden;
}