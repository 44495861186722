@charset "UTF-8";


/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 62.5%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  color: #000;
  font-feature-settings: "pkna" 1;
  text-align: justify;
  font-kerning: auto;
  font-weight: normal;
  font-size: 1.6em;
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: #000;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
*,
::before,
::after {
  box-sizing: border-box;
}

p {
  margin-bottom: 1em;
  line-height: 1.6;
}

img {
  vertical-align: middle;
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #308bbb;
  -webkit-transition: opacity 200ms ease-out;
  -moz-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}
a:hover {
  text-decoration: none;
  opacity: 0.7;
}
a img {
  -webkit-transition: opacity 200ms ease-out;
  -moz-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}
a img:hover {
  opacity: 0.7;
}

table {
  width: 100%;
}

th {
  font-weight: bold;
}

.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

body {
  background-color: #000;
  background-image: url(../img/bg.gif);
}

.header {
  display: flex;
  justify-content: center;
  height: 85px;
  padding-top: 9px;
}
.header__logo {
  width: 237px;
}

.footer {
  padding-bottom: 20px;
}
.footer .footertag {
  margin-top: 40px;
}
.footer .footertag .tickercontainer {
  height: auto !important;
}
.footer .footertag__item {
  padding: 0 !important;
}
.footer .footertag__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  height: 52px;
  padding: 0 10px;
  border: 1px solid #000;
  white-space: nowrap;
}
.footer .pagenav {
  position: relative;
  margin-top: 34px;
}
.footer .pagenav__item--gohome {
  width: 114px;
  margin: auto;
}
.footer .pagenav__item--gotop {
  width: 39px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.footer .copyright {
  display: block;
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
  margin-top: 24px;
}

.wrapper {
  margin: auto;
}
@media screen and (min-width: 767px) {
  .wrapper {
    width: 700px;
    padding: 0 15px;
  }
}
@media screen and (min-width: 1080px) {
  .wrapper {
    width: 1050px;
  }
}
@media screen and (min-width: 1440px) {
  .wrapper {
    width: 1440px;
  }
}

body.tag {
  width: 100%;
  margin: 0;
}
body.search {
  margin: 0;
}

.tag {
  width: 330px;
  margin: 20px auto 0 auto;
}
@media screen and (min-width: 767px) {
  .tag {
    width: 700px;
    padding: 0 15px;
  }
}
@media screen and (min-width: 1080px) {
  .tag {
    width: 1050px;
  }
}
@media screen and (min-width: 1440px) {
  .tag {
    width: 1440px;
  }
}
.tag__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tag__list::after {
  content: "";
  display: block;
  width: 100px;
}
.tag__item {
  flex-grow: 100;
}
.tag__item:first-child {
  flex-grow: 0;
}
@media screen and (min-width: 980px) {
  .tag__item {
    min-width: auto;
  }
}
.tag__link {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: 2px solid #000;
  height: 52px;
  background: #fff;
  color: #000;
  padding: 0 10px;
}
.tag__link--dnp-shuei-mgothic {
  font-family: tbcgothic-std, sans-serif;
  font-weight: 800;
  font-style: normal;
  flex-grow: 2;
}
.tag__link--kantypos-415 {
  font-family: kan415typos-std, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.tag__link--ro-brush {
  font-family: ro-brush-std, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.tag__link--toppan-bunkyu-midashi-min {
  font-family: toppan-bunkyu-midashi-min-st, serif;
  font-weight: 900;
  font-style: normal;
}
.tag__link--tbcgothic {
  font-family: tbcgothic-std, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.tag__link--vdl-logojrblack {
  font-family: vdl-logojrblack, sans-serif;
  font-weight: 900;
  font-style: normal;
}
.tag__link--dnp-shuei-gothic-gin {
  font-family: dnp-shuei-gothic-gin-std, sans-serif;
  font-weight: 500;
  font-style: normal;
}
.tag__link--vdl-v7marugothic {
  font-family: vdl-v7marugothic, sans-serif;
  font-weight: 900;
  font-style: normal;
}
.tag__link.italic {
  font-style: italic;
}
.tag__link.bg-white {
  background: #fff;
}
.tag__link.bg-blue {
  background: #3ebee6;
}
.tag__link.bg-pink {
  background: #e79dc2;
}

.sns {
  margin-top: 36px;
}
.sns__head {
  width: 75px;
  height: auto;
  margin: 0 auto 21px auto;
}
.sns__list {
  display: flex;
  justify-content: center;
}
.sns__item:not(:first-child) {
  margin-left: 21px;
}
.sns__link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  transition: all 0.2s;
}
.sns__link:hover {
  opacity: 1;
  color: #fff;
}
.sns__link--twitter {
  font-size: 2rem;
}
.sns__link--twitter .fab {
  transform: translate(2px, 1px);
}
.sns__link--twitter:hover {
  background: #1da1f2;
}
.sns__link--fb {
  font-size: 2rem;
}
.sns__link--fb .fab {
  transform: translate(1px, 1px);
}
.sns__link--fb:hover {
  background: #3c5a99;
}

.snsbox {
  width: 330px;
  margin: 60px auto 0 auto;
}
@media screen and (min-width: 767px) {
  .snsbox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .snsbox .fb, .snsbox .twitter {
    width: 330px;
    padding: 0 15px;
  }
}
.snsbox .fb + .twitter {
  margin-top: 30px;
}
@media screen and (min-width: 767px) {
  .snsbox .fb + .twitter {
    margin-top: 0;
  }
}

.nopost {
  color: #fff;
  text-align: center;
}

.tagpage .tag__header,
.search .tag__header {
  padding: 0 20px;
}
.tagpage .tag__title,
.search .tag__title {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.4;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 100%;
  color: #fff;
  text-align: center;
}

.kv {
  width: 100%;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.kv ul,
.kv ul li {
  float: left;
  display: inline;
  overflow: hidden;
}

.kv ul li img {
  width: 100%;
  display: none;
}

.wideslider_base {
  top: 0;
  position: absolute;
}

.wideslider_wrap {
  top: 0;
  position: absolute;
  overflow: hidden;
}

.slider_prev,
.slider_next {
  top: 0;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  cursor: pointer;
}

.slider_prev {
  background: #000;
}

.slider_next {
  background: #000;
}

.pagination {
  display: none;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 15px;
  text-align: center;
  position: absolute;
  z-index: 200;
}

.pagination a {
  margin: 0 5px;
  width: 15px;
  height: 15px;
  display: inline-block;
  overflow: hidden;
  background: #333;
}

.pagination a.active {
  filter: alpha(opacity=100) !important;
  -moz-opacity: 1 !important;
  opacity: 1 !important;
}

/* =======================================
    ClearFixElements
======================================= */
.kv ul:after {
  content: ".";
  height: 0;
  clear: both;
  display: block;
  visibility: hidden;
}

.kv ul {
  display: inline-block;
  overflow: hidden;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("fonts/slick.eot");
  src: url("fonts/slick.eot?#iefix") format("embedded-opentype"), url("fonts/slick.woff") format("woff"), url("fonts/slick.ttf") format("truetype"), url("fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 1;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "FontAwesome";
  font-size: 32px;
  line-height: 1;
  opacity: 0.75;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 20px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 20px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: #eee531;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.preload-modal {
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  overflow: hidden;
}

#loading {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  transform: translate(-50%, 0);
  opacity: 0;
}
#loading div {
  width: 45%;
}
@media screen and (min-width: 767px) {
  #loading div {
    width: 20%;
  }
}
#loading div img {
  width: 100%;
}
#loading div.loading-anime1 {
  margin-top: -50px;
}
#loading div.loading-anime2 {
  margin-top: 50px;
}

#first-word {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  font-family: "YuGothic", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 2;
  letter-spacing: 3px;
  opacity: 0;
}
@media screen and (min-width: 767px) {
  #first-word {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

#first-logo {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60vw;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  margin: 0 auto;
  transform: translate(-50%, 0);
}
@media screen and (min-width: 767px) {
  #first-logo {
    width: 430px;
  }
}
#first-logo > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
#first-logo > div div {
  opacity: 0;
}
#first-logo > div .text-3 {
  margin: 0 0 0 -3px;
}
#first-logo > div .text-4 {
  margin: 0 1px 0 -5px;
}
#first-logo > div .text-6 {
  margin: 0 -3px 0 -6px;
}
@media screen and (min-width: 767px) {
  #first-logo > div .text-3 {
    margin: 0 0 0 -3px;
  }
  #first-logo > div .text-4 {
    margin: 0 1px 0 -7px;
  }
  #first-logo > div .text-6 {
    margin: 0 -3px 0 -6px;
  }
}
@media screen and (min-width: 767px) {
  #first-logo .f-stage {
    width: 430px;
  }
}
#first-logo .f-stage div {
  height: 9vw;
}
@media screen and (min-width: 767px) {
  #first-logo .f-stage div {
    height: 65px;
  }
}
#first-logo .f-stage div img {
  height: 100%;
}
@media screen and (min-width: 767px) {
  #first-logo .f-stage div img {
    width: initial;
  }
}
#first-logo .s-stage {
  margin: 5px 5px 5px -5px;
}
@media screen and (min-width: 767px) {
  #first-logo .s-stage {
    width: 430px;
    margin: 5px 10px 5px -10px;
  }
}
#first-logo .s-stage div {
  height: 9vw;
}
@media screen and (min-width: 767px) {
  #first-logo .s-stage div {
    height: 65px;
  }
}
#first-logo .s-stage div img {
  height: 100%;
}
@media screen and (min-width: 767px) {
  #first-logo .s-stage div img {
    width: initial;
  }
}
#first-logo .ja {
  width: 80%;
  margin: 3vw 5px 0 -5px;
}
@media screen and (min-width: 767px) {
  #first-logo .ja {
    width: 290px;
    margin-top: 20px;
    font-size: 30px;
  }
}
#first-logo .ja img {
  height: 100%;
}
@media screen and (min-width: 767px) {
  #first-logo .ja img {
    width: initial;
  }
}
#first-logo .ja div {
  display: flex;
  height: 4vw;
  align-items: center;
}
@media screen and (min-width: 767px) {
  #first-logo .ja div {
    height: 23px;
  }
}
#first-logo .ja div:nth-child(1) {
  margin: 0 -4px 0 0;
}
#first-logo .ja div:nth-child(3) {
  height: 3vw;
  margin: 0 -5px -5px;
}
#first-logo .ja div:nth-child(5) {
  margin: 0 -2px 0 -4px;
}
#first-logo .ja div:nth-child(6) {
  margin: 0 -2px 0 0;
}
#first-logo .ja div:nth-child(7) {
  height: 3.9vw;
  margin: 0 -7px 0 -5px;
}
#first-logo .ja div:nth-child(8) {
  margin: 0 -1px 0 -3px;
}
#first-logo .ja div:nth-child(9) {
  margin: 0 0 0 -5px;
}
#first-logo .ja div:nth-child(10) {
  min-width: 2.5vw;
  max-width: 2.5vw;
}
@media screen and (min-width: 767px) {
  #first-logo .ja div:nth-child(3) {
    height: 18px;
    margin-bottom: -5px;
  }
  #first-logo .ja div:nth-child(5) {
    height: 23px;
    margin: 0 -2px 0 -4px;
  }
  #first-logo .ja div:nth-child(7) {
    height: 21px;
    margin: 0 -7px;
  }
  #first-logo .ja div:nth-child(8) {
    margin: 0 -3px 0 -5px;
  }
  #first-logo .ja div:nth-child(9) {
    margin: 0 0 0 -5px;
  }
  #first-logo .ja div:nth-child(10) {
    min-width: 15px;
    max-width: 15px;
    margin: 0 0 0 -5px;
  }
  #first-logo .ja div:nth-child(10) img {
    width: 100%;
  }
}

#kv {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  background: url("../img/kv.jpg") no-repeat;
  background-size: 1000vw;
  background-position-x: 50%;
  background-position-y: 80%;
}
@media screen and (min-width: 767px) {
  #kv {
    background-size: 400vh;
  }
}

.blinking {
  animation: blink 3s alternate;
  opacity: 1 !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  2% {
    opacity: 0.2;
  }
  3% {
    opacity: 0.85;
  }
  5% {
    opacity: 0.4;
  }
  6% {
    opacity: 1;
  }
}
.scroll {
  background: url(../img/scroll.svg) no-repeat;
  display: block;
  width: 30px;
  height: 12px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-size: 100%;
  opacity: 0;
  animation: scroll 3.5s ease 1.5s normal infinite none running;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.news {
  height: 28px;
  background: #fff;
  width: 100%;
  white-space: nowrap;
}
.news__list {
  height: 28px;
  font-family: "Noto Sans JP", sans-serif !important;
  font-weight: 500 !important;
}
.news__item {
  font-size: 1.3rem !important;
  line-height: 28px !important;
}
.news__item + .news__item {
  margin-left: 1em;
}
.news__date, .news__title {
  line-height: 1 !important;
  display: inline-block;
}
.news__date + .news__title {
  border-left: 1px solid #000;
  margin-left: 5px;
  padding-left: 5px;
}

.latest {
  margin-top: 34px;
}
.latest__item {
  position: relative;
  padding: 0 13px;
}
.latest__item .pr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  background: #000;
  position: absolute;
  top: 0;
  left: 25px;
}
.latest__item .pr img {
  width: 14px;
  height: auto;
}
.latest__item img {
  height: 100%;
  object-fit: cover;
}
.latest .slick-track {
  display: flex;
}
.latest .slick-slide {
  height: auto;
}

.gnav {
  margin-top: 59px;
}
@media screen and (min-width: 980px) {
  .gnav__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.gnav__item + .gnav__item {
  margin-top: 14px;
}
@media screen and (min-width: 980px) {
  .gnav__item + .gnav__item {
    margin-top: 0;
    margin-left: 14px;
  }
}
.gnav__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 325px;
  height: 50px;
  margin: auto;
  border: 3px solid #999999;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 2px rgba(246, 235, 190, 0.7), 0px 0px 20px 4px rgba(246, 235, 190, 0.9) inset;
  background: -moz-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
  background: -webkit-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 116.26, color-stop(0, white), color-stop(0.2935, #fffefc), color-stop(0.5008, #fdfcf3), color-stop(0.681, #fbf7e3), color-stop(0.7114, #fbf6e0), color-stop(0.8147, #faf5dc), color-stop(0.9067, #f9f2d2), color-stop(0.9941, #f6ecc0), color-stop(1, #f6ecbe));
  background: -o-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
  background: -ms-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
}
.gnav__link--about img {
  width: 239px;
  height: auto;
}
.gnav__link--iriguchi img {
  width: 175px;
  height: auto;
}
.gnav__link--favorit img {
  width: 220px;
  height: auto;
}

.search {
  margin-top: 27px;
}
@media screen and (min-width: 399px) {
  .search {
    margin-top: 60px;
  }
}
.search__head {
  width: 365px;
  margin: auto;
}
.search .keyword {
  width: 325px;
  margin: 28px auto 0 auto;
  position: relative;
}
.search .keyword__input {
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 10px;
}
.search .keyword__input::placeholder {
  font-weight: 900;
  color: #ccc;
}
.search .keyword__btn {
  position: absolute;
  top: 10px;
  right: 5px;
  border: none;
  background: transparent;
}
.search .keyword__btn img {
  width: 15px;
  height: auto;
}

.entry {
  width: 325px;
  margin: 50px auto 0 auto;
}
@media screen and (min-width: 767px) {
  .entry {
    width: 700px;
    margin: 50px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1080px) {
  .entry {
    width: 1050px;
  }
}
@media screen and (min-width: 1440px) {
  .entry {
    width: 1440px;
  }
}
@media screen and (min-width: 767px) {
  .entry__block {
    padding: 0 15px;
    margin-bottom: 30px;
    display: block !important;
  }
}
.entry a {
  color: #000;
}
.entry__img-wrapper {
  width: 312px;
  margin: auto;
  border-radius: 5px;
  position: relative;
}
.entry__img-wrapper::before {
  content: "";
  border-radius: 5px;
  background-image: linear-gradient(to right, #fff, #fff 100px, transparent 3px, transparent 8px), linear-gradient(to right, #fff, #fff 100px, transparent 3px, transparent 8px), linear-gradient(to bottom, #fff, #fff 100px, transparent 3px, transparent 8px), linear-gradient(to bottom, #fff, #fff 120px, transparent 10px, transparent 8px);
  background-size: 120px 5px, 140px 5px, 5px 120px, 5px 140px;
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.entry__img-wrapper--new {
  border: 5px solid #eee531;
  position: relative;
}
.entry__img-wrapper--new::before {
  display: none;
}
.entry__img-wrapper--new .new {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
}
.entry__img {
  width: 302px;
  height: 200px;
  object-fit: cover;
}
.entry__body {
  display: flex;
  justify-content: space-between;
  height: 105px;
  border: 3px solid #999999;
  padding: 15px 15px;
  transform: translateY(-20px);
  border-radius: 15px;
  box-shadow: 0px 0px 6px 2px rgba(246, 235, 190, 0.7), 0px 0px 20px 4px rgba(246, 235, 190, 0.9) inset;
  background: -moz-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
  background: -webkit-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 116.26, color-stop(0, white), color-stop(0.2935, #fffefc), color-stop(0.5008, #fdfcf3), color-stop(0.681, #fbf7e3), color-stop(0.7114, #fbf6e0), color-stop(0.8147, #faf5dc), color-stop(0.9067, #f9f2d2), color-stop(0.9941, #f6ecc0), color-stop(1, #f6ecbe));
  background: -o-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
  background: -ms-radial-gradient(50% 50%, circle farthest-corner, white 0%, #fffefc 29.35%, #fdfcf3 50.08%, #fbf7e3 68.1%, #fbf6e0 71.14%, #faf5dc 81.47%, #f9f2d2 90.67%, #f6ecc0 99.41%, #f6ecbe 100%);
}
.entry__sign {
  width: 76px;
  height: 65px;
}
.entry__sign img {
  width: 76px;
  height: 65px;
  object-fit: cover;
  border-radius: 10px;
}
.entry__txt {
  width: 189px;
  font-family: toppan-bunkyu-midashi-go-std, sans-serif;
  font-weight: 900;
  font-style: normal;
  display: flex;
  flex-direction: column;
}
.entry__title {
  margin-top: 5px;
  line-height: 1.2;
}
.entry__name {
  order: -1;
  font-size: 1.2rem;
  font-feature-settings: "palt";
  letter-spacing: 0.2px;
}
.entry__date {
  font-size: 0.8rem;
  margin-top: auto;
  text-align: right;
}
.entry .tag {
  width: 320px;
  margin: 0 auto 0 auto;
  transform: translateY(-10px);
}
.entry .tag__link {
  font-size: 1rem;
  height: 20px;
  border-width: 1px;
}
.entry__block + .entry__block {
  margin-top: 20px;
}
@media screen and (min-width: 767px) {
  .entry__block + .entry__block {
    margin-top: 0;
  }
}
.entry .btn.loadMoreBtn {
  display: block;
  color: #fff;
  font-family: toppan-bunkyu-midashi-go-std, sans-serif;
  font-style: normal;
  font-size: 2rem;
  text-align: center;
  margin: 20px 0 0 0;
  border: 1px solid #fff;
  padding: 20px;
}
.entry .btn.loadMoreBtn::after {
  content: "";
  font-family: "FontAwesome";
  font-size: 2rem;
  margin-left: 5px;
}
.entry .btn.loadMoreBtn:hover {
  text-decoration: none;
}

.campaign {
  padding: 80px 20px 29px 20px;
}
.campaignnav {
  max-width: 1240px;
  margin: 40px auto 0 auto;
}
.campaignnav__list {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 767px) {
  .campaignnav__list {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 767px) {
  .campaignnav__item {
    width: 50%;
  }
}
@media screen and (min-width: 587px) {
  .campaignnav__item {
    width: 100%;
  }
}

.about__wrapper {
  padding: 0 20px;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  margin: 25px auto 0 auto;
}
@media screen and (min-width: 767px) {
  .about__wrapper {
    width: 700px;
    padding: 0 15px;
  }
}
@media screen and (min-width: 1080px) {
  .about__wrapper {
    width: 1050px;
  }
}
@media screen and (min-width: 1440px) {
  .about__wrapper {
    width: 1050px;
  }
}
.about__head {
  width: 258px;
  margin: auto;
}
.about__txt {
  font-size: 1.4rem;
  line-height: calc(20 / 14);
  margin-top: 18px;
}
.about__txt p + p {
  margin-top: 1em;
}
.about__txt .note {
  font-size: 1.1rem;
}
.about__favorite {
  margin-top: 63px;
}
.about__favorite p {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.4rem;
  line-height: 1.6428571429;
  text-align: center;
  margin-top: 23px;
}
.about__favorite tr, .about__favorite th, .about__favorite td {
  display: block;
}
.about__favorite th {
  font-family: toppan-bunkyu-midashi-go-std, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.about__favorite tr:last-child {
  margin-top: 30px;
}
.about__favorite input[type=text] {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 16px;
  padding: 5px;
}
.about__favorite textarea {
  width: 100%;
  height: 150px;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 16px;
  padding: 5px;
}
.about__favorite input[type=submit] {
  border: 2px solid #fff;
  background: #000;
  color: #fff;
  border-radius: 0;
  font-size: 1.6rem;
  width: 100px;
  height: 40px;
}
.about__favorite .validation {
  width: 25px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  margin-left: 5px;
}
.about__favorite .validation.any {
  background: #808080;
}
.about__favorite .validation.required {
  background: #ff0000;
}
.about__en {
  margin-top: 47px;
}